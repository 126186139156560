html,body {
  height:100% !important;
  margin: 0;
}

body {
  background-image: url("./assets/background3.png");
  color: #a9adb8;
  background-color: #0a101e;
  font-family: Inter,sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6em;
}

.App {
  text-align: center;
}

.full-height {
  height:75vh;
}



.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.sp-phone {
  display: flex;
  align-items: center;
  padding-left: 25px;
  position: relative;
}

.sp-phone svg {
  fill: #fff;
  width: 15px;
}

.sp-phone .sp-phone-no {
  padding-left: 15px;
  color: #fec544;
}

.nav-item {
  z-index:10000;
}
.text-blue-50 {
  font-size: 30px;
  margin-top: -6px;
  margin-bottom: 10px;
  font-weight: 300;
  color: #fec544;
}

.st-text-block {
  color:#a9adb8;;
  text-align:left;
}

.st-text-block-title {
  color:aliceblue;
}
.st-text-block-subtitle {
  color:#fec544;
}
.st-section-heading-title {
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fec544;
  position: relative;
  z-index: 10;
  letter-spacing: 1px;
}

.st-section-heading-title:before {
  content: "";
  position: absolute;
  background-color: #3f4551;
  width: 170px;
  height: 2px;
  bottom: -16px;
  left: 50%;
  transform: translate(-50%);
}

.st-section-heading-title:after {
  content: "";
  position: absolute;
  background-color: #fec544;
  width: 70px;
  height: 2px;
  bottom: -16px;
  left: 50%;
  transform: translate(-50%);
}


.st-section-heading-subtitle {
  text-transform: uppercase;
  position: absolute;
  top: 100px;
  left: 50%;
  z-index:1;
  transform: translate(-50%);
  font-size: 90px;
  font-weight: 700;
  color: #151b2980;
  margin: -17px 0 0;
  width: 100%;
}

.st-about-img-wrap {
  padding-right: 30px;
  height:100%;
  position: relative;
  z-index: 199;
}

.st-bg {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.st-about-img {
  background-image: url('./assets/hero-img.jpg');
  height:100%
}

.st-form-field input, .st-form-field textarea {
  display: block;
  width: 100%;
  color: #a9adb8;
  padding: 12px 20px;
  border: 1px solid #232935;
  background-color: #101624;
  height: 52px;
  border-radius: 7px;
  transition: all .4s ease;
}

.st-btn {
  width:180px;
}

.st-btn.st-style1, .st-btn.st-style2 {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: Roboto,sans-serif;
  line-height: 1.5em;
  font-weight: 500;
  padding: 12px 1.5em;
  border-radius: 50px;
  min-width: 170px;
  border: none;
  outline: none;
  transition: all .4s ease;
  text-transform: capitalize;
  cursor: pointer;
}

.st-btn.st-style1.st-color1 {
  background-color: #fec544;
  color: #070d1b;
}

.st-form-field textarea {
  height: 150px;
}

.st-height-b0 {
  height: 0px;
}

.st-contact-title {
  font-size: 30px;
  font-weight: 400;
  margin-top: -6px;
  margin-bottom: 22px;
}

.st-contact-text {
  margin-top: -6px;
  margin-bottom: 23px;
}

.st-single-contact-info {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}

.st-single-contact-info .st-icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #3f4551;
  border-radius: 50%;
  background-color: #070d1b;
  width: 50px;
  height: 50px;
  margin-right: 20px;
  transition: all .4s ease;
}

.st-single-contact-info .st-icon-wrap svg {
  scale: 1.1;
}

.st-single-info-details a {
  display: block;
  margin-bottom: -2px;
  color: inherit;
  text-decoration: none;
  transition: all .3s ease;
}

.st-single-info-details span {
  display: block;
  margin-bottom: -2px;
}

.st-social-link {
  display: flex;
}

.st-social-link .st-social-btn.active {
  padding-right: 15px;
}

.st-social-link .st-social-btn:not(:last-child) {
  margin-right: 20px;
}

.st-social-link .st-social-btn {
  border-radius: 50px;
  background-color: #070d1b;
  border: 0.5px solid #3f4551;
  transition: all .4s ease;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: fit-content;
}

.st-social-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  text-align: center;
  transition: all .4s ease;
  background-color: #070d1b;
}

.st-social-link a {
  color: inherit;
  text-decoration: none;
}

.st-social-link .st-social-btn:hover .st-icon-name, .st-social-link .st-social-btn.active .st-icon-name {
  max-width: 140px;
  margin-right:30px;
}

.st-social-link .st-social-btn .st-icon-name {
  font-weight: 600;
  color: #fdfeff;
  overflow: hidden;
  max-width: 0;
  transition: all .5s ease;
  
}
.st-social-link .st-social-btn .st-social-icon svg {
  scale: 1.5;
}

*, :after, :before {
  box-sizing: border-box;
}

.display-hero {
  font-size: 120px;
  margin-bottom: 24px;
  font-weight: 900;
  color: #fdfeff;
  line-height: 1;
}
.display-hero-sub {
  font-size: 36px;
  margin-bottom: 41px;
  font-weight: 300;
  color: #a9adb8;
}

.st-resume-heading {
  display: flex;
  align-items: center;
}

.st-resume-heading .st-resume-heading-title {
  margin: 0 0 0 30px;
  font-size: 36px;
  font-weight: 400;
}

.st-height-b50 {
  height: 50px;
}

.st-resume-timeline-wrap {
  padding-left: 40px;
  margin-left: 20px;
  border-left: 1px solid #232935;
}

.st-resume-timeline {
  position: relative;
  padding-top: 1px;
  margin-bottom: 50px;
  display: inline-block;
}

.st-resume-timeline:before {
  position: absolute;
  content: "";
  border: 2px solid #232935;
  border-radius: 50%;
  background-color: #101624;
  width: 13px;
  height: 13px;
  top: 0px;
  left: -47px;
  transition: all .4s ease;
}

.st-resume-timeline .st-resume-timeline-title {
  font-size: 24px;
  font-weight: 400;
  color: #fec544;
  margin-top: -7px;
  margin-bottom: 8px;
}

.st-resume-timeline .st-resume-timeline-duration {
  font-size: 18px;
  margin-bottom: 21px;
}

.st-resume-timeline-subtitle  {
  font-size: 24px;
  font-weight: 400;
}

.st-resume-timeline-subtitle a {
  font-size: 14px;
  font-weight: 400;
  color: #3f4551;
}


.portfolio-img {
  width:80%;
}


.btn-primary {
  --bs-btn-color: #000000;
  --bs-btn-bg: #fec544;
  --bs-btn-border-color: #fec544;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #fec544;
  --bs-btn-hover-border-color: #fec544;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #fec544;
  --bs-btn-active-border-color: #fec544;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #fec544;
  --bs-btn-disabled-border-color: #fec544;
}

.btn:hover {
  color: #000000;
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
